import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { FilledButton } from '@jsluna/button';
import { Display2 } from '@jsluna/typography';
import { TextInputField } from '@jsluna/form';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const Card = makeShortcode("Card");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#split-up-content-with-headings"
            }}>{`Split up content with headings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-descriptive-headings"
            }}>{`Use descriptive headings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#front-load-your-content"
            }}>{`Front-load your content`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#cut-down-your-content"
            }}>{`Cut down your content`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#only-give-information-when-its-needed"
            }}>{`Only give information when it's needed`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "split-up-content-with-headings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#split-up-content-with-headings",
        "aria-label": "split up content with headings permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Split up content with headings`}</h2>
    <p>{`Split up blocks of text with frequent headings so people can easily find the information they’re looking for.`}</p>
    <p>{`It’s best to use a heading every time you start talking about something new. Design content so you can limit the text under each heading to a few sentences.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p><strong>Log in to your account</strong></p> <p>Use your email and password you set when you registered to log in to your account.</p>
    <p><strong>Create an account</strong></p> <p>On the website, follow these steps: 
    <ul>
    <li>Go to ‘Account’ to register</li>
    <li>Add in your email and personal details</li>
    <li>Create a secure password</li>
    </ul>
    </p>
    <p><strong>Update your account</strong></p> <p>Log in to your account via the website and from there you’ll be able to view your orders, update your details or add payment methods.</p> 
  </Do>
  <Dont mdxType="Dont">
    <p><strong>Account information</strong></p> <p>To log in to your account, use your email and password you set when you registered. If you’ve forgotten your password you can reset your password using your email.</p>
    <p>If you need to create an account, go to ‘Account’ on the website, then add in your email and create a secure password.</p>
    <p>To make changes to your account, you’ll need to log in via the website. From here you’ll be able to view your orders, update your details or add payment methods.</p>
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "use-descriptive-headings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-descriptive-headings",
        "aria-label": "use descriptive headings permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Use descriptive headings`}</h2>
    <p>{`Help people scan through content by writing headings in a descriptive way. The main message or action you want to get across should be in the heading and then expanded upon in the supporting text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Card className="ln-u-border" mdxType="Card">
      <p><strong>Update your address</strong></p>
      <p>We'll make this new address your primary delivery address with us.</p>
      <FilledButton fullWidth mdxType="FilledButton">Update address</FilledButton>
    </Card>
  </Do>
  <Dont mdxType="Dont">
    <Card className="ln-u-border" mdxType="Card"> 
      <p><strong>Are you sure?</strong></p>
      <p>We'll make this new address your primary delivery address with us.</p>
      <FilledButton fullWidth mdxType="FilledButton">Update address</FilledButton>
    </Card>
  </Dont>
    </Guideline>
    <p>{`Generic or vague headings don’t give enough useful information and that prevents people quickly finding the information they need.  `}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p><strong>Deliveries are delayed right now</strong></p> <p>Because of road closures,
    your delivery might be delayed. Please keep checking back for more information.</p>
  </Do>
  <Dont mdxType="Dont">
    <p><strong>Important information</strong></p> <p>Because of road closures,
    your delivery might be delayed. Please keep checking back for more information.</p>
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "front-load-your-content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#front-load-your-content",
        "aria-label": "front load your content permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Front-load your content`}</h2>
    <p>{`Front-loading content means putting the most important information first. As people are more likely to notice content at the top of the page, you should design the hierarchy of your content so the most important information is at the very top.`}</p>
    <p>{`You should also front-load sentences by putting the words you think people will be looking for at the start of the sentence.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <span>
      <b>Your password</b> is a 4-digit number on the back of your membership
      card.
    </span>
  </Do>
  <Dont mdxType="Dont">
    <span>
      Your membership card has a 4-digit number on the back which is used as{' '}
      <b>your password</b>.
    </span>
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "cut-down-your-content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cut-down-your-content",
        "aria-label": "cut down your content permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Cut down your content`}</h2>
    <p>{`People don’t want to read lots of content, so get the message across using as few words as possible without losing the meaning.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
  <div>Log in to check out</div>
  <div>Changes saved</div>
  <div>Out of stock</div>
  </Do>
  <Dont mdxType="Dont">
  <div>You must log in before you can check out</div>
  <div>Your changes have been successfully saved</div>
  <div>This item is currently unavailable for collection or delivery</div>
  </Dont>
    </Guideline>
    <p>{`If there are words that aren't serving a purpose by adding new or important information, remove them from your content.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    That password didn't look right. Please try again.
  </Do>
  <Dont mdxType="Dont">
    Log in error: you entered an incorrect password. Please try and log in again.
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "only-give-information-when-its-needed",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#only-give-information-when-its-needed",
        "aria-label": "only give information when its needed permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Only give information when it's needed`}</h2>
    <p>{`People are unlikely to remember information if it’s given to them at a point where they can’t do anything about it. Too much information will also overwhelm people and they'll struggle to focus on the task at hand. `}</p>
    <p>{`So reveal new information to people in a progressive manner at the right time and in the right place. `}</p>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help answer any questions around content or get involved with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      